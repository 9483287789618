
import React, { useContext, useRef, useState } from 'react';

import { classNames } from 'primereact/utils';

import { useNavigate, BrowserRouter } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import logo_with_text from './images/logo-with-text.PNG';
export const ContactUs = () => {
    const [isHidden, setIsHidden] = useState(false);
    const navigate = useNavigate();
    const [visibleTop, setVisibleTop] = useState(false);

    const toggleMenuItemClick = () => {
        setIsHidden((prevState) => !prevState);
    };
    const customHeader = (
        <React.Fragment>
            <img src={logo_with_text} alt="Sakai Logo" height="45" className="mr-2 lg:mr-2" />
        </React.Fragment>
    );


    return (
        <>
            <div className="card flex justify-content-center">
                <Sidebar visible={visibleTop} position="top" onHide={() => setVisibleTop(false)} header={customHeader} style={{ height: "200px" }} >
                    <div className="custom-header">
                        <hr />
                    </div>
                    <div onClick={() => { navigate("/"); window.location.reload() }}>
                        <h5 style={{}}>Home</h5>
                    </div>
                    <div onClick={() => { navigate("/contactUs"); window.location.reload() }}>
                        <h5 style={{}}>Contact Us</h5>
                    </div>
                </Sidebar>
            </div>
            <div className="header py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between right lg:static">
                <div onClick={() => { navigate("/"); window.location.reload() }} className="flex align-items-center" style={{ maxWidth: "225px" }}>
                    <img src={logo_with_text} alt="Sakai Logo" height="45" className="mr-2 lg:mr-2" />
                </div>

                <div className="contact">
                    <a href="contactus" onClick={toggleMenuItemClick}>
                        <span className="text-700">Contact Us</span>
                    </a>
                </div>


                <div className='hamburger-menu'>
                    <i className="pi pi-bars text-4xl cursor-pointer block lg:hidden text-700" onClick={(e) => setVisibleTop(true)}></i>

                </div>


            </div>


               
                <div id="hero" className="flex flex-column pt-4  px-4 lg:px-6 overflow-hidden" style = {{color: "#ffffff", backgroundColor: "#9E005D"}}>
                    <div className="grid mt-4 pb-2 md:pb-5" >
                        <div className="col-12 lg:col-6 my-auto flex flex-column lg:align-items-center centered" style={{ borderRadius: '8px', textAlign: "center" }}>
                            <span className="text-gray-900 text-2xl line-height-3 mr-0 md:mr-2 centered">
                                <div>
                                    <span style={{marginBottom:"10px", color: "white", fontSize: "40px"}} class="white">Mooruk Design Solutions</span>
                                </div>
                            </span>
                            <span className="text-gray-700 text-xl line-height-3 mr-0 md:mr-2 centered"  style={{ textAlign: "center", color: "white", fontSize: "20px", marginTop: "10px" }} class="white" >
                                Phone Number: (613) 889-4979
                            </span>
                            <span className="text-gray-700 text-xl line-height-3 mr-0 md:mr-2 centered" style={{ textAlign: "center", color: "white", fontSize: "20px", marginTop: "10px" }} class="white" >
                                Email: info@mooruk.com
                            </span>
                            <span className="text-gray-700 text-xl line-height-3 mr-0 md:mr-2 centered"  style={{ textAlign: "center", color: "white", fontSize: "20px", marginTop: "10px"  }} class="white">
                                Ottawa, Canada
                            </span>

                        </div>

                        <div className="centered flex-order-1 sm:flex-order-2 col-12 lg:col-6 pb-0" style={{ borderRadius: '8px', textAlign: "center" }}>
                            <img src={require("./images/iStock-538353855 copy.jpg")} className="w-10" alt="mockup" />
                        </div>
                    </div>

                    <div style={{textAlign: "center"}}>
                        <h2 className='text-3xl font-bold text-gray-900 line-height-2 pb-8 pt-8 centered' style={{color: "white", paddingTop: "40px", paddingBottom: "40px"}} class = "white">We look forward to partnering with you</h2>
                    </div>


                </div>





                <div className="py-4 px-4 mx-0 mt-8 lg:mx-8">
                        <div className="grid" style={{textAlign: "center"}}>
                            <div id="bottom" className="col-12 md:col-2" style={{ marginTop: '-1.5rem' }}>
                                <div style={{ height: "20px" }}>
                                    <img src={require(`./images/logo.png`)} alt="footer sections" width="20" height="20" className="mr-1" />
                                </div>
                                <h4 className="font-medium text-xl line-height-1 text-900">MOORUK</h4>
                            </div>

                            <div className="col-12 md:col-3"></div>
                            <div id="bottom" className="col-12 md:col-2" style={{ marginTop: '-1.5rem' }}>

                                <h4 className="font-medium text-xl line-height-1 text-700" style={{fontSize: "10px"}}>Copyrights &copy; 2023.</h4>
                                <h4 className="font-medium text-xl line-height-1 text-700">All Rights Reserved.</h4>
                            </div>
                            <div className="col-12 md:col-3"></div>
                            <div id="bottom" className="col-12 md:col-2" style={{ marginTop: '-1.5rem' }}>

                                <h4 className="font-medium text-xl line-height-1 text-700">+1 (613) 889-4979</h4>
                                <h4 className="font-medium text-xl line-height-1 text-700">info@mooruk.com</h4>

                            </div>
                        </div>


                    </div>
          
        
      </>
    );
};
