import logo from './logo.svg';
import './App.css';
import { StyleClass } from 'primereact/styleclass';

import {
  useEventListener,
  useMountEffect,
  useUnmountEffect,
} from "primereact/hooks";
import React, { useContext, useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Divider } from 'primereact/divider';
import { PrimeReactContext } from "primereact/api";
import { Link, Route, Routes } from 'react-router-dom';
import { Landing } from './landing';
import { BrowserRouter } from 'react-router-dom';
import { ContactUs } from './ContactUs.js';

const App = () => {
  return (
    <>
      
      <BrowserRouter>
      <link id="theme-css" href="./themes/lara-light-indigo/theme.css" rel="stylesheet"></link>
        <Routes>
          <Route path="" element={<Landing />} />
          <Route path="/contactus" element={<ContactUs />} />


        </Routes>
    
    
      </BrowserRouter>
    </>
  );
}

export default App;
